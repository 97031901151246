import { ColDef, GridApi, IRowNode, GridReadyEvent } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes, GridTypes } from "../../../../../../enums";
import { buildProcessListData } from "../../../../../../pages/change/organisations/settingsView/processesSettings/ProcessesSettings_model";
import ProcessesApi, { ProcessesApi as IProcessesApi } from "../../../../../../services/api/v2/processes/Processes.api";
import ProjectProcessesApi from "../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { buildTree } from "../../../../../util/Helpers";
import {
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum,
  ProcessColDefFieldNamesEnum,
  ProjectProcessColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { LINK_TO_IMPACT_GROUPS_MODAL_CONFIG } from "../ImpactsGrid/ImpactsGrid_modals";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { GetProjectProcessesGridActions } from "./ProjectProcessesGridView_actions";
import { ProjectProcessesGridColumnBuilder } from "./ProjectProcessesGridView_columns";
import {
  LINK_TO_IMPACTS_MODAL_CONFIG,
  LINK_TO_LOCATIONS_MODAL_CONFIG,
  SHOW_ADD_PROCESSES_FROM_TEMPLATE_MODAL_CONFIG,
  SHOW_PROJECT_PROCESS_DELETE_CONFIRM_MODAL
} from "./ProjectProcessesGridView_modals";
import { AppGridToolbarType } from "../../types/AppGrid_types";

export class ProjectProcessGridModel {
  organisationId: number;
  projectId: number;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  userCanViewImpacts: boolean;
  userCanEditImpacts: boolean;
  processesGridColumnBuilder: ProjectProcessesGridColumnBuilder;
  @observable selectedItems: number[] = [];
  @observable.ref gridActions: IGridUiAction[] = [];
  httpProgress: IProgressIndicatorModel;
  processProvider: IProcessesApi;
  projectProcessProvider = ProjectProcessesApi;
  refreshDataHandler: any;
  gridModalBuilder: GridModalBuilder;
  @observable filterStoreAddon: FilterStoreAddon;
  @observable columnStateAddon: ColumnStateAddon;
  @observable textWrapAddon: TextWrapperAddon;
  gridApi: GridApi;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  @observable gridColumns: ColDef<any, any>[];
  onFieldUpdate: () => void;
  gridToolbarType: AppGridToolbarType;
  @observable preSelectedItemIds: number[];
  gridType: EntityTypes | GridTypes;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    refreshDataHandler: any,
    onFieldUpdate: () => void,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType
  ) {
    makeObservable(this);
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanEditImpacts = PermissionsContext.canEditField(PermissionFields.IMPACTS, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.httpProgress = ProgressIndicatorModel;
    this.processProvider = ProcessesApi;
    this.refreshDataHandler = refreshDataHandler;
    this.onFieldUpdate = onFieldUpdate;
    this.gridModalBuilder = new GridModalBuilder();
    this.gridToolbarType = gridToolbarType;
    this.preSelectedItemIds = preSelectedItemIds;
    this.gridType = gridType;
  }

  onMount = async () => {
    this.generateAddons();
    this.generateGridConfig();
    this.generateActions();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  generateGridConfig = () => {
    this.processesGridColumnBuilder = new ProjectProcessesGridColumnBuilder({
      canEdit: this.userCanEditImpacts && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewImpacts: this.userCanViewImpacts,
      onFieldUpdate: this.onFieldUpdate,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        ProcessColDefFieldNamesEnum.UniqueIdentifier,
        ProcessColDefFieldNamesEnum.Level1,
        ProcessColDefFieldNamesEnum.Level2,
        ProcessColDefFieldNamesEnum.Level3,
        ProcessColDefFieldNamesEnum.Level4,
        ProcessColDefFieldNamesEnum.Level5,
        ProcessColDefFieldNamesEnum.ScopeItems,
        ProjectProcessColDefFieldNamesEnum.ProcessApps,
        ProjectProcessColDefFieldNamesEnum.ProcessRoles,
        ProjectProcessColDefFieldNamesEnum.ProcessJobRoles,
        ProjectProcessColDefFieldNamesEnum.StakeholderOwners,
        CommonColDefFieldNamesEnum.ProgressStatus,
        ProcessColDefFieldNamesEnum.IsInScope,
        ProjectProcessColDefFieldNamesEnum.ImpactGroups,
        ProjectProcessColDefFieldNamesEnum.Impacts,
        ProjectProcessColDefFieldNamesEnum.Actions,
        ImpactColDefFieldNamesEnum.Locations,
        CommonColDefFieldNamesEnum.NoteCount,
        ProjectProcessColDefFieldNamesEnum.Tags,
        CommonColDefFieldNamesEnum.CreatedBy,
        CommonColDefFieldNamesEnum.CreatedAt,
        CommonColDefFieldNamesEnum.ModifiedBy,
        CommonColDefFieldNamesEnum.UpdatedAt
      ]
    });
    this.gridColumns = this.processesGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetProjectProcessesGridActions(this));
  };

  @action
  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, this.gridType ?? EntityTypes.PROCESSES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.PROCESSES,
      filterHasChangedFn: this.setIsFilterChanged
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.PROCESSES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  onFirstDataRendered = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.preSelectedItemIds?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  assignProcesses = async (selectedItems: any) => {
    this.httpProgress.showOverlay();
    const res = await this.processProvider
      .createOrganisationProcess(this.organisationId, selectedItems)
      .then(e => this.refreshDataHandler());
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    return res.payload;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  showProcessConfirmDeleteModal = () => {
    return SHOW_PROJECT_PROCESS_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  showLinkToImpactGroups = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_IMPACT_GROUPS_MODAL_CONFIG(this.assignImpactGroupsToProjectProcesses))
      .generateModal();
  };

  showLinkToImpacts = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_IMPACTS_MODAL_CONFIG(this.assignImpactsToProjectProcesses))
      .generateModal();
  };

  showLinkToLocations = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_LOCATIONS_MODAL_CONFIG(this.assignLocationsToProjectProcesses))
      .generateModal();
  };

  showCoreProcessesLinkModal = async () => {
    return this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(
        SHOW_ADD_PROCESSES_FROM_TEMPLATE_MODAL_CONFIG(
          await this.loadOrgProcessData(),
          this.assignProjectProcessesToProject,
          this.selectedItems
        )
      )
      .generateModal();
  };

  loadOrgProcessData = async () => {
    this.httpProgress.showOverlay();
    let res = await this.processProvider.getAll(this.organisationId);

    this.httpProgress.hideOverlay();
    if (res.isError) {
      return;
    }

    return this.setOrgProcessData(res.payload);
  };

  setOrgProcessData = (processData: any[]) => {
    const mappedResults = processData.map(e => {
      return {
        ...e,
        parent: e.parent === 0 || !e.parent ? "" : e.parent
      };
    });
    const treeData = buildTree(mappedResults);

    const orgProcessData = buildProcessListData(treeData);

    return orgProcessData;
  };

  assignProjectProcessesToProject = async (selectedItems: any) => {
    this.httpProgress.showOverlay();
    const res = await this.projectProcessProvider.createProjectProcess(
      this.organisationId,
      this.projectId,
      selectedItems
    );
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    ModalContext.hide();
    return res.payload;
  };

  assignImpactGroupsToProjectProcesses = async impactGroupIds => {
    await this.projectProcessProvider.addMultipleImpactGroups(
      this.organisationId,
      this.projectId,
      impactGroupIds,
      this.selectedItems
    );
    ModalContext.hide();
  };

  assignImpactsToProjectProcesses = async impacts => {
    await this.projectProcessProvider.addMultipleImpacts(
      this.organisationId,
      this.projectId,
      this.selectedItems,
      impacts
    );
    ModalContext.hide();
  };

  assignLocationsToProjectProcesses = async locations => {
    await this.projectProcessProvider.addBulkLocations(
      this.organisationId,
      this.projectId,
      this.selectedItems,
      locations
    );
    ModalContext.hide();
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.projectProcessProvider
      .deleteRange(this.organisationId, this.projectId, itemIds)
      .then(e => this.refreshDataHandler && this.refreshDataHandler());
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };
}
